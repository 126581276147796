import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, ModalTitle } from "react-bootstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { db } from "util/db";
import { Stage, Layer, Line, Text } from "react-konva";
import { FormModal } from "./Form-Modal";

export function ModalComponent({ show, closeModal, image }) {
  const emptyObject = {
    key: Math.random(),
    titulo: "",
    descricao: "",
    acao: "",
    severidade: "Alta"
  };
  const [formSection, setFormSection] = useState(emptyObject);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const stageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: Math.round(window.innerWidth * 0.7),
    height: Math.round(window.innerHeight * 0.7)
  });
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (image?.img instanceof File) {
      const reader = new FileReader();
      reader.onload = e => {
        const result = e.target.result;
        setImageSrc(result);
        console.log("imageeeee in modal", result);
      };
      reader.readAsDataURL(image.img);
    }
  }, [image]);

  const handleAddToCart = async () => {
    setIsLoading(true);
    try {
      const stage = stageRef.current;
      const dataURL = stage.toDataURL();
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = dimensions.width;
      tempCanvas.height = dimensions.height;
      const ctx = tempCanvas.getContext("2d");

      const backgroundImage = new Image();
      backgroundImage.src = imageSrc;
      backgroundImage.onload = () => {
        ctx.drawImage(
          backgroundImage,
          0,
          0,
          dimensions.width,
          dimensions.height
        );

        const stageImage = new Image();
        stageImage.src = dataURL;
        stageImage.onload = async () => {
          ctx.drawImage(stageImage, 0, 0, dimensions.width, dimensions.height);

          const combinedDataURL = tempCanvas.toDataURL();

          await db.images.add({
            img: combinedDataURL,
            key: formSection.key
          });
          dispatch({ type: "ADD_TO_REPORT", payload: { ...formSection } });

          setIsLoading(false);
          setFormSection(emptyObject);
          closeModal();
        };
      };
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
      setFormSection(emptyObject);
      closeModal();
    }
  };

  const handleForm = (e, key) => {
    const value = e.target.value;
    setFormSection(prev => ({ ...prev, [key]: value }));
  };

  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  const [history, setHistory] = useState([]);
  const isDrawing = useRef(false);

  const handleMouseDown = e => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = e => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleClearAll = () => {
    setLines([]);
    setHistory([]);
  };

  const handleUndo = () => {
    if (lines.length === 0) return;
    const newHistory = [...history, lines[lines.length - 1]];
    setHistory(newHistory);
    setLines(lines.slice(0, -1));
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (imageLoaded && divRef.current) {
        const width = divRef.current.clientWidth;
        const height = divRef.current.clientHeight;
        setDimensions({ width, height });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [imageLoaded]);

  return (
    <Modal
      isOpen={show}
      size="xl"
      backdrop="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal-fullscreen-lg-down modal-xl"
      fullscreen
    >
      <ModalHeader closeButton toggle={closeModal}>
        <ModalTitle>Registro de ocorrência</ModalTitle>
      </ModalHeader>
      <ModalBody className="position-relative d-inline-block d-flex flex-lg-row flex-column justify-content-between align-items-center gap-2 w-100 h-100">
        <div className="mb-3 mb-lg-0 w-75 w-lg-75 h-100 px-2 px-lg-0 position-relative d-inline-block">
          <div
            ref={divRef}
            style={{
              position: "relative",
              width: "100%",
              height: "calc(100% - 50px)"
            }}
          >
            <img
              src={imageSrc}
              alt="Markable"
              className="d-block w-100 h-100 py-2 object-fit-contain"
              onLoad={handleImageLoad}
            />
            <Stage
              ref={stageRef}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              width={dimensions.width}
              height={dimensions.height}
              style={{
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Layer>
                <Text text="Just start drawing" x={5} y={30} />
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={5}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      line.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                ))}
              </Layer>
            </Stage>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <Button color="secondary" onClick={handleUndo} className="me-2">
              <i className="fas fa-undo me-2"></i>
              Desfazer
            </Button>
            <Button color="danger" onClick={handleClearAll}>
              <i className="fas fa-trash-alt me-2"></i>
              Apagar marcação
            </Button>
          </div>
        </div>
        <FormModal formSection={formSection} handle={handleForm} />
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={closeModal}>
          Sair
        </Button>
        {isLoading ? (
          <div className="spinner-border text-primary" role="status" />
        ) : (
          <Button color="primary" onClick={handleAddToCart}>
            Adicionar ao relatório
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
