import React, { useState } from "react";
import { connect } from "react-redux";
import DefaultMap from "components/maps/DefaultMap";



const OrderQuote = () => {

  const [center] = useState({lat: 45.9432,lng: 24.9668});
  const [zoom] = useState(5);

  return (
    <div className="chart-container">
      <div className="row">
        <div className="col-md-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <DefaultMap center={center} zoom={zoom} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(OrderQuote);