import React, { useState } from "react";
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { productSelectOption1, productSelectOption2, productSelectOption3, productSelectOption4 } from 'util/data/SelectMenuData'
import Select from 'react-select';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";

const UploadFile = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedProducts, setSelectedProducts] = useState({ Aerolevantamento: [], Cartografia: [], Inspecao: [], Aerofilmagem: [] });

  const token = useSelector((state) => state.auth.accessToken);
  const decodedToken = jwtDecode(token);
  const userName = decodedToken.username;

  const onDrop = (acceptedFiles) => {
    const maxFileSize = 500 * 1024 * 1024;

    const filteredFiles = acceptedFiles.filter(file => {
      return file.size <= maxFileSize &&
        ['image/jpeg', 'image/png', 'application/zip', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/x-compressed'].includes(file.type);
    });

    if (filteredFiles.length !== acceptedFiles.length) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Alguns arquivos foram ignorados devido ao tamanho ou tipo inválido.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
    }

    setFiles([...files, ...filteredFiles]);
  };

  const handleUpload = async () => {

    if (!projectName) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Por favor, digite o nome do projeto.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      return;
    }

    if (files.length === 0) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Por favor, selecione pelo menos um arquivo para enviar.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      return;
    }

    setLoading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        // Solicita a URL de upload para cada arquivo
        // const { data } = await axios.post('https://ds-digital-api.vercel.app/generate-upload-url', {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/generate-upload-url`, {
          fileName: file.name,
          contentType: file.type,
          projectName, // Incluído aqui
        });

        // Faz o upload do arquivo para o Google Cloud Storage
        return axios.put(data.url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
      });

      await Promise.all(uploadPromises);

      console.log(projectName + description + userName)

      // Preparar formData com os metadados     
      const formData = new FormData();
      formData.append('projectName', projectName);
      formData.append('description', description);
      formData.append('usuario', userName);

      // Adicionar informações dos arquivos ao formData
      files.forEach((file, index) => {
        formData.append(`fileNames[${index}]`, file.name);
        formData.append(`fileSizes[${index}]`, file.size);
      });

      Object.keys(selectedProducts).forEach(key => {
        if (Array.isArray(selectedProducts[key]) && selectedProducts[key].length > 0) {
          formData.append(key, JSON.stringify(selectedProducts[key]));
        } else if (selectedProducts[key]) {
          formData.append(key, JSON.stringify([selectedProducts[key]]));
        }
      });

      // const url = `https://ds-digital-api.vercel.app/upload-metadata/${projectName}`;
      const url = `${process.env.REACT_APP_API_URL}/upload-metadata/${projectName}`;

      // Enviar metadados para o backend
      await axios.post(url, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Arquivos carregados com sucesso e metadados enviados.',
        icon: 'success',
        confirmButtonText: 'Ok'
      });

      setFiles([]);

    } catch (error) {
      console.error('Erro durante o processo de upload:', error);
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao carregar arquivos ou enviar metadados. Tente novamente.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    } finally {
      setLoading(false);
    }

  };

  const handleProductChange = (selectedOptions, { name }) => {
    const selectedLabels = selectedOptions ? selectedOptions.map(option => option.label) : [];
    setSelectedProducts(prevState => ({
      ...prevState,
      [name]: selectedLabels
    }));
  };

  const removeFile = (fileIndex) => {
    setFiles(files.filter((_, index) => index !== fileIndex));
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <label><strong>Nome do Projeto</strong></label>
        <div>
          <input
            type="text"
            className="form-control react-form-input"
            aria-describedby="emailHelp"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Título do projeto"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group row"></div>
        <label><strong>Descrição</strong></label>
        <div>
          <textarea
            rows="4"
            className="form-control react-form-input"
            aria-describedby="emailHelp"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descreva o seu projeto"
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group row"></div>
        <label><strong>Produtos</strong></label>
        <div className="row">
          <div className="col-md-3">
            <Select
              defaultValue=""
              isMulti
              placeholder="Aerolevantamento"
              name="Aerolevantamento"
              options={productSelectOption1}
              onChange={handleProductChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="col-md-3">
            <Select
              defaultValue=""
              isMulti
              placeholder="Cartografia"
              name="Cartografia"
              options={productSelectOption2}
              onChange={handleProductChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="col-md-3">
            <Select
              defaultValue=""
              isMulti
              placeholder="Inspeção"
              name="Inspecao"
              options={productSelectOption3}
              onChange={handleProductChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="col-md-3">
            <Select
              defaultValue=""
              isMulti
              placeholder="Aerofilmagem"
              name="Aerofilmagem"
              options={productSelectOption4}
              onChange={handleProductChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="form-group row"></div>
        <label><strong>Upload de Imagens</strong></label>
        <div className="custom-file">
          <Dropzone onDrop={onDrop} disabled={loading}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={`dropzone-container ${loading ? 'loading' : ''}`}>
                {loading && <div className="loader"></div>}
                <input {...getInputProps()} />
                <p className="text-center">
                  Arraste e solte os arquivos aqui, ou clique para selecionar os arquivos.
                </p>
              </div>
            )}
          </Dropzone>

          {files.length > 0 && (
            <div className="mt-4 bg-light p-4 rounded">
              <p className="mb-4"><strong>Arquivos Carregados</strong></p>
              <ul className="list-group">
                {files.map((file, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
                    <button onClick={() => removeFile(index)} className="btn btn-danger btn-sm">Excluir</button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="mt-4">
            <button className="btn btn-primary" onClick={handleUpload}>
              Enviar Dados
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
