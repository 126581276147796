import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmailVerification = () => {
    const { token } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        verifyEmailToken(token);
    }, [token]);

    const verifyEmailToken = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify_email?token=${token}`);
            Swal.fire({
                title: 'E-mail Verificado!',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/login');
                }
            });
        } catch (error) {
            Swal.fire({
                title: 'Erro!',
                text: error.response?.data?.error || 'Ocorreu um erro desconhecido.',
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push('/login');
                }
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Verificação de E-mail</h3>
                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Carregando...</span>
                                    </div>
                                </div>
                            ) : (
                                <p className="text-center">Sua verificação de e-mail foi processada. Por favor, verifique as notificações para mais informações.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;