import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Projects.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';

const Projects = ({ email, token }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (!email) return;
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/projects/${email}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.data.length === 0) {
                setProjects([
                    { name: "DEMONSTRAÇÃO - Falésia", type: "pointcloud", demo: true },                    
                    { name: "DEMONSTRAÇÃO - Infraestrutura", type: "pointcloud", demo: true },
                    { name: "DEMONSTRAÇÃO - Mapeamento", type: "pointcloud", demo: true },
                    { name: "DEMONSTRAÇÃO - Acompanhamento de Obra", type: "tour", demo: true },
                    { name: "DEMONSTRAÇÃO - Aerolevantamento", type: "tour", demo: true },
                ]);
            } else {
                setProjects(response.data.map(project => ({ ...project, demo: false })));
            }
            setLoading(false);
            setError(null);
        }).catch(err => {
            console.error('Erro ao buscar projetos:', err);
            setError('Falha ao buscar projetos');
            setLoading(false);
        });
    }, [email, token]);

    const handleOpenProject = (project) => {
        const path = project.type === 'tour' ? '/tour' : '/gemeo-digital';
        const isDemo = project.demo ? 'true' : 'false';

        if (project.type === 'tour') {
            history.push({
                pathname: path,
                state: { project, demo: isDemo }
            });
        } else {
            const url = `${path}?projectName=${encodeURIComponent(project.name)}&projectType=${project.type}&demo=${isDemo}`;
            window.location.href = url;
        }
    };

    const getTypeText = (type) => {
        switch (type) {
            case 'tour':
                return 'Tour 360°';
            case 'pointcloud':
                return 'Modelo 3D';
            default:
                return type;
        }
    };

    const handleDeleteProject = (project) => {
        const { type, name } = project;

        Swal.fire({
            title: 'Você tem certeza?',
            text: "Você não poderá reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_URL}/projects/delete/${email}/${type}/${name}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(response => {
                        setProjects(prevProjects => prevProjects.filter(p => p !== project));
                        Swal.fire(
                            'Deletado!',
                            'Seu projeto foi deletado.',
                            'success'
                        );
                    })
                    .catch(error => {
                        console.error('Erro ao deletar projeto:', error);
                        Swal.fire(
                            'Erro!',
                            'Falha ao deletar o projeto.',
                            'error'
                        );
                    });
            }
        });
    };

    const renderTable = () => (
        <table className="table">
            <thead>
                <tr>
                    <th>Nome do Projeto</th>
                    <th>Tipo</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                {projects.map((project, index) => (
                    <tr key={index}>
                        <td>{project.name}</td>
                        <td>{getTypeText(project.type)}</td>
                        <td>
                            <button className="btn btn-outline-primary btn-sm mr-2" onClick={() => handleOpenProject(project)}>
                                Abrir Projeto
                            </button>
                            <button className="btn btn-outline-danger btn-sm" onClick={() => handleDeleteProject(project)}>
                                Remover
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderCards = () => (
        <div className="row">
            {projects.map((project, index) => (
                <div className="col-md-4 col-sm-6 mb-4" key={index}>
                    <div className="card h-100 project-card">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">{project.name}</h5>
                            <p className="card-text mb-auto">Tipo: {getTypeText(project.type)}</p>
                            <button
                                className="btn btn-primary mt-2 align-self-start"
                                onClick={() => handleOpenProject(project)}
                            >
                                Abrir Projeto
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    if (loading) return <p>Carregando projetos...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="container mt-3">
            <h4>Meus Projetos</h4>
            {email === 'test@demo.com' ? renderTable() : renderCards()}
        </div>
    );
};

const mapStateToProps = state => ({
    email: state.auth.email,
    token: state.auth.accessToken
});

export default connect(mapStateToProps)(Projects);