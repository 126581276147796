import React, { useState } from "react";
import { ModalComponent } from "components/modal/Modal";
import { ReportList } from "components/reportList/ReportList";
import { Button } from "react-bootstrap";

import "./screenshot.css";

export function Screenshot({ tagReference }) {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [image, setImage] = useState({ img: null, base64: "" });
  function handleOpen() {
    setIsOpenModal(true);
  }
  function handleClose() {
    setIsOpenModal(false);
  }
  const openModal = async e => {
    e.preventDefault();
    setIsLoadingModal(true);
    try {
      if (tagReference.current) {
        let screenshot;
        if (
          tagReference.current.components &&
          tagReference.current.components.screenshot
        ) {
          screenshot = tagReference.current.components.screenshot.getCanvas(
            "perspective"
          );
        } else if (tagReference.current.querySelector("canvas")) {
          screenshot = tagReference.current.querySelector("canvas");
        } else {
          console.error("Unable to capture screenshot: Unsupported scene type");
          return;
        }
        const image64 = screenshot.toDataURL();
        const imageJpg = await new Promise(resolve => {
          screenshot.toBlob(
            blob => {
              const jpgImage = new File([blob], "screenshot.jpg", {
                type: "image/jpeg"
              });
              resolve(jpgImage);
            },
            "image/jpeg",
            1
          );
        });
        setImage({ img: imageJpg, base64: image64 });
      }
      handleOpen();
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoadingModal(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between position-fixed bottom-5 right-7p5 w-4vw h-4vw z-index-2500">
        {isLoadingModal ? (
          <div className="spinner-border text-primary" role="status" />
        ) : (
          <Button
            onClick={e => openModal(e)}
            variant="link"
            className="text-white w-full h-full"
          >
            <i className="fas fa-camera fa-2x" />
          </Button>
        )}
        <ReportList className="text-white  bg-primary" />
      </div>
      <ModalComponent
        show={isOpenModal}
        closeModal={handleClose}
        image={image}
      />
    </>
  );
}
