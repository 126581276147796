import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
  Toast,
  ToastBody,
  ToastHeader
} from "reactstrap";
import { db } from "util/db";
import { useLiveQuery } from "dexie-react-hooks";
import axios from "axios";
import imageCompression from "browser-image-compression";
import "./reportlist.css";
export function ReportList() {
  const { report: reports } = useSelector(state => state.report || []);
  const { email } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [showReports, setShowReports] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const images = useLiveQuery(() => db.images.toArray());

  const handleVisible = () => {
    setShowReports(prev => !prev);
  };
  const getImage = key => {
    if (images && images.length > 0 && key) {
      const [image] = images.filter(i => i.key === key);
      return image ? image.img : null;
    }
    return null;
  };
  const handleDownloadOrShare = fileUrl => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      if (navigator.share) {
        navigator
          .share({
            title: "Relatório PDF",
            text: "Confira o relatório gerado",
            url: fileUrl
          })
          .catch(error => console.log("Erro ao compartilhar", error));
      } else {
        window.open(fileUrl, "_blank");
      }
    } else {
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "relatorio.pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => console.error("Erro ao baixar o arquivo"));
    }
  };

  const handleFinish = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("email", email);

      const items = await Promise.all(
        reports.map(async report => {
          const image = await getImage(report.key);
          const imageName = `image_${report.key}.jpg`;

          if (image) {
            const imageBlob = await fetch(image).then(res => res.blob());

            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 800,
              useWebWorker: true
            };

            const compressedFile = await imageCompression(imageBlob, options);
            formData.append("imageBinaries", compressedFile, imageName);

            return {
              titulo: report.titulo,
              descricao: report.descricao,
              acao: report.acao,
              severidade: report.severidade,
              image: imageName
            };
          }
        })
      );

      formData.append("items", JSON.stringify(items));
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/report/generate`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      handleDownloadOrShare(response.data.fileUrl);
      handleVisible();
      dispatch({ type: "CLEAR_REPORTS" });
    } catch (error) {
      console.error("Error generating report:", error);
      setToastMessage(
        error.response?.data?.message || "Erro ao gerar relatório"
      );
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };
  const removeReport = id => {
    dispatch({ type: "REMOVE_REPORT", payload: id });
  };
  return (
    <div className="me-4 position-relative report-list-container">
      {reports.length > 0 && (
        <Button
          color="link"
          onClick={handleVisible}
          className="position-relative text-white w-100 h-100"
          style={showReports ? {} : { zIndex: 1001 }}
        >
          <i className="fas fa-list fa-2x" />
          <span className="badge bg-danger position-absolute top-0 end-0">
            {reports.length}
          </span>
        </Button>
      )}

      <div
        className="sidebar bg-white shadow-lg position-fixed h-100 top-0 w-100 w-md-50 report-list-side-bar "
        style={{
          left: showReports ? "50%" : "100%",
          transform: showReports ? "translateX(0%)" : "translateX(100%)",
          "@media (max-width: 850px)": {
            left: showReports ? "0" : "100%"
          }
        }}
      >
        <div className="w-50 h-100 d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center px-3 text-primary">
            <h5 className="mb-0">Snapshots do relatório</h5>
            <Button
              color="primary"
              onClick={handleVisible}
              style={{ zIndex: 1004 }}
            >
              <i className="fas fa-times" />
            </Button>
          </div>
          <div className="p-3 gap-2 flex-grow-1 overflow-auto">
            <ListGroup className="text-primary">
              {reports.length === 0 ? (
                <ListGroupItem>Carrinho vazio</ListGroupItem>
              ) : (
                reports.map(item => (
                  <ListGroupItem
                    key={item.key}
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: "1rem" }}
                  >
                    <img
                      src={getImage(item.key)}
                      width="25%"
                      alt={item.titulo}
                    />
                    <div className="d-flex flex-column">
                      <p className="mb-0">{item.titulo}</p>
                      <Button
                        color="danger"
                        onClick={() => removeReport(item.key)}
                        style={{ zIndex: 1005 }}
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                      </Button>
                    </div>
                  </ListGroupItem>
                ))
              )}
            </ListGroup>
          </div>
          <div className="d-flex justify-content-center p-3">
            <Button
              color="secondary"
              onClick={handleFinish}
              disabled={isLoading}
              style={{ zIndex: 1006 }}
            >
              {isLoading ? (
                <Spinner size="sm" color="light" />
              ) : (
                "Salvar relatório"
              )}
            </Button>
          </div>
        </div>
      </div>
      <Toast
        isOpen={showToast}
        toggle={() => setShowToast(false)}
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 1007 }}
      >
        <ToastHeader icon="danger">Erro</ToastHeader>
        <ToastBody>{toastMessage}</ToastBody>
      </Toast>
    </div>
  );
}
