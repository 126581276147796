import React from "react";
import { connect } from "react-redux";
import UploadFile from "components/widgets/UploadFile";



const Uploads = () => {

  return (
    <div className="chart-container">
      <div className="row">
        <div className="col-md-12">
          <div className="roe-card-style">
            <div className="roe-card-header">
              <span className="hash">#</span> <h4>Processe seus Dados</h4>
            </div>
            <div className="roe-card-body">
              <UploadFile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Uploads);
