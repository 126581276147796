import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import TourVirtual from 'views/projects/TourVirtual';


const MediaDialog = props => {
    const { modal, setmodal, className, currentMedia } = props
    return (
        <Modal
            centered
            isOpen={modal}
            toggle={setmodal}
            className={className}
            size="xl"
        >
            <ModalBody className="media-modal-body">
                {currentMedia && currentMedia.component ? (
                    // Renderize o componente definido em currentMedia.component
                    currentMedia.component
                ) : (
                    // Se não houver componente definido, renderize o Tour por padrão
                    <TourVirtual />
                )}
            </ModalBody>
        </Modal>
    );
};

export default MediaDialog;