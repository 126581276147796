import styled from "styled-components";

const pageviewsChartWrapper = styled.div`
  .analytics-processwidget-card {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
      padding: 24px;
      letter-spacing: 0.02em;
      text-align: center;
    }

    .text-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .desc1 {
      margin: 10px 0;
      font-size: 20px;
      line-height: 1;
      letter-spacing: 0.02em;
      text-align: center;
    }
    .desc2 {
      color: #9e9e9e;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }
  }
`;
export default pageviewsChartWrapper;
