export const BasicSelectOption = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" }
];

export const productSelectOption1 = [
  { value: "pontosApoio", label: "Inclui Pontos de Apoio", color: "#00B8D9", isFixed: true },
  { value: "scaleBar", label: "Inclui Scale Bar", color: "#0052CC", disabled: true },
  { value: "ortomosaicoGeoTIFF", label: "Ortomosaico (GeoTIFF)", color: "#5243AA" },
  { value: "ortomosaicoECW", label: "Ortomosaico (ECW)", color: "#FF5630", isFixed: true },
  { value: "modelagem3D", label: "Modelagem 3D (OBJ)", color: "#FF8B00" },
  { value: "nuvemPontosLAS", label: "Nuvem de Pontos (LAS)", color: "#FFC400" },
  { value: "modeloDigitalSuperficieGeoTIFF", label: "Modelo Digital de Superfície(GeoTIFF)", color: "#36B37E" },
  { value: "modeloDigitalTerrenoGeoTIFF", label: "Modelo Digital de Terreno (GeoTIFF)", color: "#00875A" },
  { value: "curvasNivelSHP", label: "Curvas de Nível (SHP)", color: "#253858" }
];

export const productSelectOption2 = [
  { value: "cadastro2D", label: "Cadastro 2D (DXF)", color: "#00B8D9", isFixed: true },
  { value: "cadastro3D", label: "Cadastro 3D (DWG)", color: "#0052CC", disabled: true },
  { value: "plantaPlanialtimatrica", label: "Planta Planialtimátrica (Topografia) (DXF e PDF)", color: "#5243AA" },
  { value: "plantaCadastral", label: "Planta Cadastral Primitiva p/ escritura (PDF)", color: "#FF5630", isFixed: true },
  { value: "memorialDescritivo", label: "Memorial Descritivo p/ escritura (PDF)", color: "#FF8B00" },
  { value: "zoneamentoAmbiental", label: "Zoneamento Ambiental (SHP)", color: "#FFC400" },
  { value: "mapeamentoApp", label: "Mapeamento de APP (PDF)", color: "#36B37E" },
  { value: "mapaDeclividade", label: "Mapa de Declividade (PDF)", color: "#00875A" },
  { value: "prad", label: "PRAD - Plano de Recuperação de Áreas Degradadas (PDF)", color: "#253858" },
  { value: "nascentesAreasMolhadas", label: "Nascentes, Áreas Molhadas e APPs (PDF)", color: "#666666" },
  { value: "mapaHipsometrico", label: "Mapa Hipsométrico (PDF)", color: "#666666" },
  { value: "car", label: "CAR - Cadastro Ambiental Rural (PDF)", color: "#666666" },
  { value: "georeferenciamentoRurais", label: "Georeferenciamento de Imóveis Rurais", color: "#666666" },
  { value: "georeferenciamentoUrbanos", label: "Georeferenciamento de Imóveis Urbanos", color: "#666666" },
];

export const productSelectOption3 = [
  { value: "fachada", label: "Fachada", color: "#00B8D9", isFixed: true },
  { value: "telhado", label: "Telhado", color: "#0052CC", disabled: true },
  { value: "usinaSolar", label: "Usina Solar", color: "#5243AA" },
  { value: "linhaTransmissao", label: "Linha de Transmissão", color: "#FF5630", isFixed: true },
  { value: "industrial", label: "Industrial", color: "#FF8B00" },
  { value: "inspecaoLinear", label: "Inspeção Linear (oleoduto, aqueduto, etc..)", color: "#FFC400" },
  { value: "inspecaoTermografica", label: "Inspeção Termográfica", color: "#36B37E" },
];

export const productSelectOption4 = [
  { value: "tour360", label: "Tour 360º", color: "#00B8D9", isFixed: true },
  { value: "fotosAereas", label: "Fotos e Filmagens Aéreas", color: "#0052CC", disabled: true },
  { value: "fotosTerrestres", label: "Fotos e Filmagens Terrestres", color: "#5243AA" },
  { value: "edicaoSemLocucao", label: "Edição Sem Locução", color: "#FF5630", isFixed: true },
  { value: "edicaoComLocucao", label: "Edição Com Locução", color: "#FF8B00" },
  { value: "entrevistas", label: "Entrevistas", color: "#FFC400" },
];


export const flavourOptions = [
  { value: "vanilla", label: "Vanilla", rating: "safe" },
  { value: "chocolate", label: "Chocolate", rating: "good" },
  { value: "strawberry", label: "Strawberry", rating: "wild" },
  { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" }
];

export const groupedOptions = [
  {
      label: "Colours",
      options: productSelectOption1
  },
  {
      label: "Flavours",
      options: flavourOptions
  }
];