import React from "react";
import { Form } from "react-bootstrap";
const formCheck = ["Alta", "Média", "Baixa"];
export function FormModal({ formSection, handle }) {
  return (
    <Form className="w-75 w-lg-75 h-75 px-0 px-lg-5">
      <Form.Group controlId="controlTitulo">
        <Form.Control
          type="text"
          value={formSection.titulo}
          placeholder="Título"
          onChange={e => handle(e, "titulo")}
          className="w-100"
        />
      </Form.Group>
      <Form.Group controlId="controlDescricao">
        <Form.Control
          as="textarea"
          rows={2}
          value={formSection.descricao}
          placeholder="Descrição"
          onChange={e => handle(e, "descricao")}
          className="w-100"
        />
      </Form.Group>
      <Form.Group controlId="controlAcao">
        <Form.Control
          as="textarea"
          rows={4}
          value={formSection.acao}
          placeholder="Ação"
          onChange={e => handle(e, "acao")}
          className="w-100"
        />
      </Form.Group>
      <Form.Group controlId="controlSeveridade" className="d-flex flex-column">
        <Form.Label>Severidade</Form.Label>
        <div className="d-flex flex-row justify-content-between mt-2">
          {formCheck.map(formValue => (
            <Form.Check
              key={formValue}
              inline
              type="radio"
              checked={formSection.severidade === formValue}
              onChange={e => handle(e, "severidade")}
              value={formValue}
              label={formValue}
            />
          ))}
        </div>
      </Form.Group>
    </Form>
  );
}
