import React, { useState } from "react";
import { connect } from "react-redux";
import PageviewsChartWidget from "components/widgets/pageviewsChartWidget/PageviewsChartWidget";
import AnalyticsProcessWidgets from "components/widgets/analyticsProcessWidgets/AnalyticsProcessWidgets";


const Dashboard = ({ sidebarTheme, layoutTheme }) => {

  return (
    <div>
      <div>
        <div className="row ma-0">
            <div className="col-12 col-xl-8 col-lg-12 col-md-12 col-sm-12 ptb-15">
              <PageviewsChartWidget sidebarTheme={sidebarTheme} />
            </div>

            <div className="col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12 ptb-15">
              <AnalyticsProcessWidgets sidebarTheme={sidebarTheme} />
            </div>
          </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Dashboard);
