import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        attachment: null,
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        const file = name === 'attachment' ? files[0] : null;

        setFormData({ ...formData, [name]: value, attachment: file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            const form = new FormData();
            form.append('name', formData.name);
            form.append('email', formData.email);
            form.append('message', formData.message);
            form.append('attachment', formData.attachment);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-email`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Resposta do servidor:', response.data);

            Swal.fire({
                icon: 'success',
                title: 'Mensagem enviada com sucesso!',
                showConfirmButton: false,
                timer: 1500,
            });

            setFormData({
                name: '',
                email: '',
                message: '',
                attachment: null,
            });

        } catch (error) {
            console.error('Erro ao enviar formulário:', error);

            Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar mensagem',
                text: 'Por favor, tente novamente mais tarde.',
            });

        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>Formulário de Contato</h2>
            <span>Formulário para teste de envio de email com anexo.</span>
            <hr />
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Nome:</label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">E-mail:</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Mensagem:</label>
                    <textarea
                        className="form-control"
                        rows="4"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Anexo:</label>
                    <input type="file" className="form-control" name="attachment" onChange={handleChange} />
                </div>

                <button type="submit" className="btn btn-primary">
                    {loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> Enviando...
                        </>
                    ) : (
                        'Enviar'
                    )}
                </button>
            </form>
        </div>
    );
};

export default ContactForm;