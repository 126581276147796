import React from "react";
export function RowTableReport({ key, filename }) {
  const handleDowload = e => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/pdf.pdf`;
    link.download = `${filename}.pdf`; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <tr key={key}>
      <td>{filename}</td>
      <td>
        <button onClick={e => handleDowload(e)}>
          <i class="fas fa-download"></i>
        </button>
      </td>
    </tr>
  );
}
