import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import './UploadFiles.css';

const UploadFiles = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const token = useSelector((state) => state.auth.accessToken);

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles.map((file) => file));
  };

  const handleUpload = () => {
    if (!projectName) {
      alert('Por favor, digite o nome do projeto.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload/ds-web-repository/${projectName}`, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Arquivos carregados com sucesso');
        setFiles([]); // Limpar a lista de arquivos após o envio bem-sucedido
      })
      .catch((error) => {
        console.error('Erro carregando arquivos:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Carregamento de Arquivos</h2>

      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Digite o nome do projeto"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          required
        />
      </div>

      <Dropzone onDrop={onDrop} disabled={loading}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={`dropzone-container ${loading ? 'loading' : ''}`}>
            {loading && <div className="loader"></div>}
            <input {...getInputProps()} />
            <p className="text-center">
              Arraste e solte os arquivos aqui, ou clique para selecionar os arquivos.
            </p>
          </div>
        )}
      </Dropzone>

      {files.length > 0 && (
        <div className="mt-4 bg-light p-4 rounded">
          <h3 className="mb-4">Arquivos Carregados</h3>
          <ul className="list-group">
            {files.map((file, index) => (
              <li key={index} className="list-group-item">
                {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
              </li>
            ))}
          </ul>
        </div>
      )}

      {files.length > 0 && !loading && (
        <div className="mt-4">
          <button className="btn btn-primary" onClick={handleUpload}>
            Enviar Arquivos
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;
