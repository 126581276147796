import React, { useState, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import omnivore from 'leaflet-omnivore';
import { Modal, ModalBody } from "reactstrap";
import { productSelectOption1, productSelectOption2, productSelectOption3, productSelectOption4 } from 'util/data/SelectMenuData'
import Select from 'react-select';
import Swal from 'sweetalert2';
import axios from "axios";
import { connect } from 'react-redux';

// Componente
const DefaultMap = (props) => {
  const { email } = props;

  // Declarando variáveis
  const [map, setMap] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [markerData, setMarkerData] = useState(null);
  const [fileName, setFileName] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalClass, setModalClass] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    aerolevantamento: [],
    cartografia: [],
    inspecao: [],
    aerofilmagem: []
  });
  const [description, setDescription] = useState('');

  const processKML = (kmlContent) => {
    const kmlLayer = omnivore.kml.parse(kmlContent);
    kmlLayer.addTo(map);
    map.fitBounds(kmlLayer.getBounds());
  };

  // CSS para o botão Enviar
  const buttonBack = {
    backgroundColor: '#035184',
    color: 'white'
  };

  useEffect(() => {

    // Inicializa o mapa Leaflet
    const leafletMap = L.map("map").setView([0, 0], 15);

    // Adiciona uma camada de mapa
    L.tileLayer("https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWxpc3NvbnBpbmEiLCJhIjoiY2xvaWx0eDc1MWY2MDJrczF2cTQ1ejc4cSJ9._p7ac_KV-vM1YhG1fE6Hlw", {
      attribution: 'DS',
    }).addTo(leafletMap);

    // Opções de mapas
    var baselayers = {
      "Mapa de rua": L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"),
      "Mapa de satélite": L.tileLayer("https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWxpc3NvbnBpbmEiLCJhIjoiY2xvaWx0eDc1MWY2MDJrczF2cTQ1ejc4cSJ9._p7ac_KV-vM1YhG1fE6Hlw"),
    };
    var overlays = {};
    L.control.layers(baselayers, overlays).addTo(leafletMap);

    // Obtém a localização do usuário (se disponível)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;
        leafletMap.setView([userLat, userLng], 15);
      });
    }

    // Inicializa o recurso de desenho (para polígonos)
    const drawnItems = new L.FeatureGroup();
    leafletMap.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        marker: false,
        circlemarker: false,
        circle: false,
        polyline: false,
      },
      edit: {
        featureGroup: drawnItems,
      },
    });
    leafletMap.addControl(drawControl);

    // Event listener para quando o usuário terminar de desenhar um polígono
    leafletMap.on(L.Draw.Event.CREATED, (event) => {

      const layer = event.layer;

      // Converte o polígono para um formato KML
      const kml = `<b>Polígono:</b><br>${polygonToKML(layer)}`;
      setMarkerData(`<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
        <Placemark>
          <Polygon>
            <outerBoundaryIs>
              <LinearRing>
                <coordinates>${polygonToKML(layer)}</coordinates>
                </LinearRing>
              </outerBoundaryIs>
            </Polygon>
          </Placemark>
        </kml>`);

      // Calcula a área do polígono (Em hectares)
      const polygonArea = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
      const polygonAreaHectares = polygonArea / 10000;
      const formattedArea = `<b>Área (hectares):</b><br>${polygonAreaHectares.toFixed(2)} ha`;

      // Cria um popup para exibir o KML e a área com espaço entre eles e em negrito
      layer.bindPopup(`${kml}<br><br>${formattedArea}`).openPopup();

      // Adiciona o polígono ao grupo desenhado
      drawnItems.addLayer(layer);
    });

    setMap(leafletMap);

    return () => {
      // Limpa o mapa quando o componente for desmontado
      leafletMap.remove();
    };
  }, [props.center, props.zoom]);


  // Função para converter um polígono em formato KML
  const polygonToKML = (polygon) => {
    const latLngs = polygon.getLatLngs()[0];
    const coordinates = latLngs.map((latLng) => `${latLng.lng},${latLng.lat},0`);
    return coordinates.join("\n");
  };


  // Função que define abertura do modal
  const openModal = () => {
    setModalIsOpen(true);
  };


  // Função que define fechamento do modal
  const closeModal = () => {
    setModalIsOpen(false);
  };


  // Use useEffect to automatically close the modal after 3 seconds
  useEffect(() => {
    if (modalIsOpen) {
      const timeout = setTimeout(() => {
        closeModal();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [modalIsOpen]);


  // Função para lidar com arquivos carregados
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Verifica a extensão do arquivo
    const validExtensions = ['.kml'];
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

    if (!validExtensions.includes(fileExtension.toLowerCase())) {
      // Se o arquivo não for KML/KMZ, exibir alerta
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor, carregue apenas arquivos KML.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return; // Interrompe a execução adicional
    }

    setUploadedFile(file);
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      processKML(text);
    };
    reader.readAsText(file);
  };

  const handleSelectChange = (optionSelected, field) => {
    const selectedValues = optionSelected.map(option => option.value);
    setSelectedOptions(prev => ({ ...prev, [field]: selectedValues }));
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Função para lidar com botão enviar acionado
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!uploadedFile && !markerData) {
      setModalClass("alert alert-danger");
      setModalMessage("Não há dados para enviar!");
      openModal();
      return;
    }

    const formData = new FormData();
    if (uploadedFile) {
      formData.append('file', uploadedFile, uploadedFile.name);
    }
    if (markerData) {
      formData.append('file', new Blob([markerData], { type: 'application/vnd.google-earth.kml+xml' }), 'markerData.kml');
    }

    // Adicionando as opções selecionadas ao formData
    formData.append('aerolevantamento', JSON.stringify(selectedOptions.aerolevantamento));
    formData.append('cartografia', JSON.stringify(selectedOptions.cartografia));
    formData.append('inspecao', JSON.stringify(selectedOptions.inspecao));
    formData.append('aerofilmagem', JSON.stringify(selectedOptions.aerofilmagem));

    formData.append('description', description);
    formData.append('email', email);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-order-quote`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setModalClass("alert alert-success");
        setModalMessage("Dados enviados com sucesso!");
        setFileName('Carregar arquivo KML');
        setUploadedFile(null);
        setMarkerData(null);
        clearMapLayers();
        resetForm();
      } else {
        throw new Error('Falha ao enviar dados');
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      setModalClass("alert alert-danger");
      setModalMessage("Erro ao enviar dados!");
    }
    openModal();
  };

  const resetForm = () => {
    setUploadedFile(null);
    setFileName('');
    setSelectedOptions({
      aerolevantamento: [],
      cartografia: [],
      inspecao: [],
      aerofilmagem: []
    });
    setDescription('');
  };

  const clearMapLayers = () => {
    if (map) {
      map.eachLayer(function (layer) {
        if (layer instanceof L.Path) {
          map.removeLayer(layer);
        }
      });
    }
  };

  return (
    <div>
      <div>

        <form onSubmit={handleSubmit}>

          <div className="mt-10">

            <div className="row" style={{ width: "100%", height: "100%" }}>

              <div className="col-md-4">

                <label><strong>Área</strong></label>

                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="exampleFormControlFile1"
                    accept=".kml,.KML"
                    onChange={handleFileUpload}
                  />
                  <label className="custom-file-label" htmlFor="exampleFormControlFile1">
                    {fileName || 'Upload KML'}
                  </label>
                </div>

                <div className="form-group row"></div>

                <div className="custom-file">
                  <label style={{ fontSize: "12px" }}>Caso não possua arquivo KML, demarque a área de interesse no mapa ao lado.</label>
                </div>

                <div className="form-group row" style={{ color: 'white' }}></div>

                <label><strong>Produtos</strong></label>
                <Select
                  value={selectedOptions.aerolevantamento.map(val => ({ label: val, value: val }))}
                  onChange={(options) => handleSelectChange(options, 'aerolevantamento')}
                  isMulti
                  placeholder="Aerolevantamento"
                  name="aerolevantamento"
                  options={productSelectOption1}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <div className="form-group row"></div>
                <Select
                  value={selectedOptions.cartografia.map(val => ({ label: val, value: val }))}
                  onChange={(options) => handleSelectChange(options, 'cartografia')}
                  isMulti
                  placeholder="Cartografia"
                  name="cartografia"
                  options={productSelectOption2}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <div className="form-group row"></div>
                <Select
                  value={selectedOptions.inspecao.map(val => ({ label: val, value: val }))}
                  onChange={(options) => handleSelectChange(options, 'inspecao')}
                  isMulti
                  placeholder="Inspeção"
                  name="inspecao"
                  options={productSelectOption3}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <div className="form-group row"></div>
                <Select
                  value={selectedOptions.aerofilmagem.map(val => ({ label: val, value: val }))}
                  onChange={(options) => handleSelectChange(options, 'aerofilmagem')}
                  isMulti
                  placeholder="Filmagem e Fotografia"
                  name="aerofilmagem"
                  options={productSelectOption4}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />

              </div>

              <div className="col-md-8">
                <div className="form-group row">
                  <div id="map" style={{ width: "100%", height: "400px" }}></div>
                </div>
              </div>
            </div>

          </div>

          <label><strong>Descrição</strong></label>
          <div className="form-group">
            <textarea
              className="form-control"
              id="description"
              name="description"
              rows="3"
              placeholder="Digite a descrição aqui..."
              onChange={handleDescriptionChange}
              value={description}
            />
          </div>

          <div className="d-flex flex-column-reverse align-items-center" style={{ width: "12%" }}>
            <button
              style={buttonBack}
              type="submit"
              className="btn form-button"
              onClick={openModal}
            >
              Enviar Solicitação
            </button>
          </div>
        </form>

      </div>

      <div>
        <Modal isOpen={modalIsOpen} className="media-modal" centered={true} size="sm" backdrop={true}>
          <ModalBody className="media-modal-body">
            <div className={modalClass}>
              <p>{modalMessage}</p>
            </div>
          </ModalBody>
        </Modal>
      </div>

    </div>
  );
};

const mapStateToProps = state => ({
  email: state.auth.email
});

export default connect(mapStateToProps)(DefaultMap);