export const rowData = [
    {
        id: 1,
        firstname: "ABC",
        address: {
            city: "Aero",
            state: "5.8 MB"
        }
    },
    {
        id: 2,
        firstname: "ABC",
        address: {
            city: "3D",
            state: "17.8 MB"
        }
    },
    {
        id: 3,
        firstname: "ABC",
        address: {
            city: "Inspeção",
            state: "23 MB"
        }
    },
    {
        id: 4,
        firstname: "ABC",
        address: {
            city: "Filmagem",
            state: "105.7 MB"
        }
    },
    {
        id: 5,
        firstname: "XPT",
        address: {
            city: "Cartografia",
            state: "2.3 GB"
        }
    },
    {
        id: 6,
        firstname: "XPT",
        address: {
            city: "Orto",
            state: "10.8 MB"
        }
    }
]