import React from "react";
import { connect } from "react-redux";
import PotreePointcloud from 'components/vr/PotreePointcloud';

const GemeoDigital = () => {
  const query = new URLSearchParams(window.location.search);
  const projectName = query.get('projectName');
  const projectType = query.get('projectType');
  const project = { name: projectName, type: projectType };
  const demo = query.get('demo');

  return (
    <div className="roe-card-style mt-15 mb-30 mlr-15 mobile-spacing-class no-box-container">
        <PotreePointcloud project={project} demo={demo} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(GemeoDigital);