import {
    withFormik
} from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Este campo é obrigatório'),
        email: Yup.string().email('Digite um e-mail válido').required('Este campo é obrigatório'),
        // role: Yup.string().required('Este campo é obrigatório'),
        password: Yup.string().required('Este campo é obrigatório'),
        passwordCheck: Yup.string().required('Este campo é obrigatório').oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
    }),
    handleSubmit: (values) => {},
    displayName: 'CustomValidationForm',
    enableReinitialize:true,
});

export default formikEnhancer;