import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const BucketList = () => {
  const [buckets, setBuckets] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = useSelector(state => state.auth.accessToken);

  useEffect(() => {
    const fetchBuckets = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/buckets/list`, {
            headers: {
                Authorization: `${token}`
            }
        })
        setBuckets(response.data.buckets || []);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      }
    };

    fetchBuckets();
  }, []);

  return (
    <div>
      <h2>Lista de Buckets</h2>
      <span><b>Fluxo:</b> <i>Frontend -&gt; API -&gt; GCP -{'>'} API -{'>'} Frontend</i></span>
      <hr/>

      {loading ? (
        <p>Carregando...</p>
      ) : (
        <ul className="list-group">
          {buckets.map((bucket, index) => (
            <li key={index} className="list-group-item">
              <strong>{index + 1}:</strong> {bucket}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BucketList;