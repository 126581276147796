import React from "react";
import { loginBack, iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import axios from 'axios'
import Swal from 'sweetalert2';

const { login } = AuthActions;

const Login = props => {
    const handleLogin = async (e) => {
        e.preventDefault();
        let { values, handleSubmit } = props;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/signin`, {
                email: values.email,
                password: values.password,
            });

            if (response.status === 200) {
                const data = {
                    token: response.data.token,
                    email: response.data.email
                };

                props.login(data);

                props.history.push('/dashboard');
            } else {
                console.error('Erro durante o login:', response.data);
            }
        } catch (error) {
            if (error.response) {
                // O servidor respondeu com um código de status fora do intervalo 2xx
                handleErrorResponse(error.response);
            } else if (error.request) {
                // A solicitação foi feita, mas não recebeu resposta
                handleNetworkError();
            } else {
                // Ocorreu um erro durante a solicitação
                console.error('Erro ao fazer solicitação de login:', error.message);
                handleGenericError();
            }
        }
    };

    const handleErrorResponse = (response) => {
        const { status, data } = response;

        if (status === 401) {
            // Senha incorreta
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'Senha incorreta. Por favor, tente novamente.',
            });
        } else if (status === 404) {
            // Usuário inexistente
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'Usuário inexistente. Verifique o email fornecido.',
            });
        } else if (status === 403) {
            // Email não verificado
            Swal.fire({
                icon: 'warning',
                title: 'Verificação necessária!',
                text: 'Por favor, verifique seu email antes de fazer login.',
            });
        } else {
            // Outros erros
            console.error('Erro durante o login:', data);
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'Ocorreu um erro durante o login. Tente novamente mais tarde.',
            });
        }
    };

    const handleNetworkError = () => {
        Swal.fire({
            icon: 'error',
            title: 'Erro de Rede!',
            text: 'Houve um problema de rede. Verifique sua conexão e tente novamente.',
        });
    };

    const handleGenericError = () => {
        Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Ocorreu um erro durante o login. Tente novamente mais tarde.',
        });
    };

    const { values, handleChange, handleBlur, errors, touched, submitCount } = props;

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0,
    };

    const Error = props => {
        const field1 = props.field;
        if ((errors[field1] && touched[field1]) || submitCount > 0) {
            return (
                <span className={props.class ? props.class : "error-msg"}>
                    {errors[field1]}
                </span>
            );
        } else {
            return <span />;
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={iconDemo} alt="icon" height="100px" />
                </div>
                <div className="login-title">Acessar sua conta</div>
                <form className="pa-24" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control react-form-input"
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                            placeholder="Email"
                        />
                        <Error field="email" />
                    </div>

                    <div className="form-group">
                        <label>Senha</label>
                        <input
                            type="password"
                            className="form-control react-form-input"
                            id="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Password"
                        />
                        <Error field="password" />
                    </div>

                    {/* <div className="form-check text-center mtb-16">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                        >
                            Lembre-me
                        </label>
                    </div> */}

                    <button type="submit" className="btn form-button">
                        Acessar
                    </button>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/forgotPassword")}
                    >
                        Esqueceu a senha?
                    </div>
                    <div
                        className="text-center link-label"
                        onClick={() => props.history.push("/register")}
                    >
                        Não tem uma conta?
                    </div>
                </form>
            </div>
        </div>
    );
};

export default compose(
    withRouter,
    enhancer,
    connect(
        null,
        { login }
    )
)(Login);
