import React, { useState } from 'react';
import { connect } from "react-redux";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2';

const PilotRegistration = () => {
    const { register, handleSubmit, setValue, reset, watch } = useForm();
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [selectedBaterias, setSelectedBaterias] = useState(null);
    const [selectedGnss, setSelectedGnss] = useState(null);
    const qtdBaterias = watch('qtdBaterias');
    const gnss = watch('gnss');

    const onSubmit = async (data) => {
        try {
            Swal.fire({
                title: 'Aguarde...',
                text: 'Enviando seus dados...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register-pilot`, data);
            if (response.status === 201) {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Cadastro realizado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });

                reset({
                    nome: '',
                    cep: '',
                    cidade: '',
                    estado: '',
                    dronePayloads: '',
                    qtdBaterias: null,
                    gnss: null,
                    habilitacao: false,
                    carro: false,
                    '4x4': false,
                    disponibilidadeViagem: false,
                    tempoOperacao: '',
                    tipoTrabalho: ''
                });
                setCidade('');
                setEstado('');
                setSelectedBaterias(null); // Limpar o select de qtdBaterias
                setSelectedGnss(null); // Limpar o select de gnss

            }
        } catch (error) {
            Swal.fire({
                title: 'Erro!',
                text: 'Ocorreu um erro ao realizar o cadastro. Tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };

    const handleCEPChange = async (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        if (cep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (!response.data.erro) {
                    setCidade(response.data.localidade);
                    setEstado(response.data.uf);
                    setValue('cidade', response.data.localidade);
                    setValue('estado', response.data.uf);
                }
            } catch (error) {
                console.error('Erro ao buscar o CEP:', error);
            }
        }
    };

    const gnssOptions = [
        { value: 'DJI D-RTK 2', label: 'DJI D-RTK 2' },
        { value: 'Emlid Reach RS+', label: 'Emlid Reach RS+' },
        { value: 'Emlid Reach RS2', label: 'Emlid Reach RS2' },
        { value: 'Emlid Reach RS3', label: 'Emlid Reach RS3' },
        { value: 'Hi-Target V-series', label: 'Hi-Target V-series' },
        { value: 'iGage GNSS', label: 'iGage GNSS' },
        { value: 'Leica GNSS receiver (GS series or better)', label: 'Leica GNSS receiver (GS series or better)' },
        { value: 'Propeller AeroPoints', label: 'Propeller AeroPoints' },
        { value: 'SinoGNSS T300 Plus', label: 'SinoGNSS T300 Plus' },
        { value: 'South Galaxy G3 RTK', label: 'South Galaxy G3 RTK' },
        { value: 'Spectra MobileMapper 300', label: 'Spectra MobileMapper 300' },
        { value: 'Stonex S10', label: 'Stonex S10' },
        { value: 'Stonex S900T', label: 'Stonex S900T' },
        { value: 'Tarsus GNSS DGPS', label: 'Tarsus GNSS DGPS' },
        { value: 'Trimble Catalyst', label: 'Trimble Catalyst' },
        { value: 'Trimble GNSS receiver (R series or better)', label: 'Trimble GNSS receiver (R series or better)' },
        { value: 'Unistrong 9970II-Pro', label: 'Unistrong 9970II-Pro' },
    ];

    const qtdBateriasOptions = Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: i === 9 ? '10+' : `${i + 1}`,
    }));

    return (
        <div className="container mt-5 mb-5">
            <div className="alert alert-primary" role="alert">
                <h3>Cadastro de Pilotos</h3>
                <p>Cadastre os seus dados no nosso banco de pilotos.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="px-3">

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="nome">Nome</label>
                            <input
                                type="text"
                                id="nome"
                                className="form-control"
                                {...register('nome', { required: 'Nome é obrigatório' })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="cep">CEP</label>
                            <input
                                type="text"
                                id="cep"
                                className="form-control"
                                {...register('cep', { required: 'CEP é obrigatório' })}
                                onBlur={handleCEPChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="cidade">Cidade</label>
                            <input
                                type="text"
                                id="cidade"
                                className="form-control"
                                value={cidade}
                                {...register('cidade')}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="estado">Estado</label>
                            <input
                                type="text"
                                id="estado"
                                className="form-control"
                                value={estado}
                                {...register('estado')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="dronePayloads">Drone & Payloads</label>
                            <input
                                type="text"
                                id="dronePayloads"
                                className="form-control"
                                {...register('dronePayloads')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Quantidade de Baterias</label>
                            <Select
                                options={qtdBateriasOptions}
                                value={selectedBaterias}
                                onChange={(option) => {
                                    setSelectedBaterias(option);
                                    setValue('qtdBaterias', option.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>GNSS</label>
                            <Select
                                options={gnssOptions}
                                value={selectedGnss}
                                onChange={(option) => {
                                    setSelectedGnss(option);
                                    setValue('gnss', option.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Deslocamento</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id="habilitacao"
                                            className="form-check-input"
                                            {...register('habilitacao')}
                                        />
                                        <label htmlFor="habilitacao" className="form-check-label">Habilitação</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id="carro"
                                            className="form-check-input"
                                            {...register('carro')}
                                        />
                                        <label htmlFor="carro" className="form-check-label">Carro</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id="4x4"
                                            className="form-check-input"
                                            {...register('4x4')}
                                        />
                                        <label htmlFor="4x4" className="form-check-label">4x4</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            id="disponibilidadeViagem"
                                            className="form-check-input"
                                            {...register('disponibilidadeViagem')}
                                        />
                                        <label htmlFor="disponibilidadeViagem" className="form-check-label">
                                            Disponibilidade para viagem
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="tempoOperacao">A quanto tempo opera drone e equipamentos operados</label>
                            <textarea
                                id="tempoOperacao"
                                className="form-control"
                                {...register('tempoOperacao')}
                            ></textarea>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="tipoTrabalho">Que tipo de trabalho já realizou com o drone?</label>
                            <textarea
                                id="tipoTrabalho"
                                className="form-control"
                                {...register('tipoTrabalho')}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div className="form-group" style={{paddingBottom: '70px'}}>
                    <button type="submit" className="btn btn-primary mt-4">
                        Cadastrar Piloto
                    </button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state.themeChanger
    };
};

export default connect(mapStateToProps, null)(PilotRegistration);