import React, { useState } from "react";
import axios from "axios";
import { loginBack, ForgotIcon } from "helper/constant";
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
            setMessage(response.data.message);
            setError('');
            Swal.fire({
                icon: 'success',
                title: 'E-mail enviado!',
                text: response.data.message,
            });
        } catch (error) {
            setError('Erro ao enviar o e-mail de redefinição.');
            setMessage('');
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'Erro ao enviar o e-mail de redefinição.',
            });
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={ForgotIcon} alt="icon" height="100px" />
                </div>
                <div className="login-title">Esqueceu sua senha?</div>
                <div className="text-center form-info-text plr-24 mt-16">
                    Preencha com seu email para redefinir
                </div>
                <form className="pa-24" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control react-form-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Digite seu email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn form-button">
                        Redefinir
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;