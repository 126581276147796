import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import customIconImage from 'assets/images/gps.png';
import customIconActive from 'assets/images/gps_active.png';

function Minimap({ images, onMarkerClick, activeMarker }) {
    const mapRef = useRef(null);

    const customIcon = L.icon({
        iconUrl: customIconImage,
        iconSize: [32, 32],
    });

    const customActive = L.icon({
        iconUrl: customIconActive,
        iconSize: [32, 32],
    });

    useEffect(() => {
        if (!mapRef.current) {
            const map = L.map('minimap', {
                center: [0, 0],
                zoom: 2
            });
            L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}').addTo(map);

            mapRef.current = map;
        }

        // Remove existing markers to avoid duplicates
        mapRef.current.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                mapRef.current.removeLayer(layer);
            }
        });

        // Add new markers
        images.forEach((image, index) => {
            if (!isNaN(image.latitude) && !isNaN(image.longitude)) { // Check for valid coordinates
                const marker = L.marker([image.latitude, image.longitude], {
                    icon: index === activeMarker ? customActive : customIcon
                }).addTo(mapRef.current);

                marker.on('click', () => {
                    onMarkerClick(index);
                    mapRef.current.eachLayer(layer => {
                        if (layer instanceof L.Marker && layer !== marker) {
                            layer.setIcon(customIcon); // Reset other markers to default icon
                        }
                    });
                    marker.setIcon(customActive); // Set active icon for clicked marker
                });

                // Set the map view to the first marker or the active marker
                if (index === activeMarker || (index === 0 && activeMarker === null)) {
                    mapRef.current.setView([image.latitude, image.longitude], 18);
                }
            }
        });

    }, [images, activeMarker, onMarkerClick]);

    return (
        <div id="minimap" style={{ width: '100%', height: '100%' }}></div>
    );
}

export default Minimap;