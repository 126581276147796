export const objetoMock = [
  {
    id: Math.random(),
    arquivo: "Imagens brutais.zip"
  },
  {
    id: Math.random(),
    arquivo: "Projeto.tif"
  },
  {
    id: Math.random(),
    arquivo: "Projeto - Relatório.PDF"
  },
  {
    id: Math.random(),
    arquivo: "Projeto.Laz"
  },
  {
    id: Math.random(),
    arquivo: "Projeto Relatório de processamento.pdf"
  },
  {
    id: Math.random(),
    arquivo: "Projeto Tour 360.zip"
  },
  {
    id: Math.random(),
    arquivo: "Projeto Vídeos.zip"
  }
];
