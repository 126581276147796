import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "aframe";
import "components/maps/modal.css";
import Minimap from "components/maps/Minimap";
import "aframe";
import axios from "axios";
import { Screenshot } from "components/screenshot/Screenshot";

function TourVirtual({ email }) {
  const [images, setImages] = useState([]);
  const [currentScene, setCurrentScene] = useState(null);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);

  const location = useLocation();
  const { project, demo } = location.state || {};

  function extractFileName(url) {
    return url.substring(url.lastIndexOf("/") + 1);
  }

  useEffect(() => {
    const loadImages = async () => {
      if (!email || !project.name) return;

      try {
        let response;
        if (demo === true || demo === "true") {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/tour-images/demo/${project.name}`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_API_URL}/tour-images/${email}/${project.name}`
          );
        }

        const imageList = response.data.map(imageData => ({
          src: imageData.url,
          fileName: extractFileName(imageData.url),
          latitude: convertDMSToDD(
            imageData.exif.GPSLatitude,
            imageData.exif.GPSLatitudeRef
          ),
          longitude: convertDMSToDD(
            imageData.exif.GPSLongitude,
            imageData.exif.GPSLongitudeRef
          )
        }));

        setImages(imageList);
        setCurrentScene(imageList[0] || null);
      } catch (error) {
        console.error("Erro ao carregar imagens:", error);
      }
    };
    loadImages();
  }, [demo, email, project.name]);

  function convertDMSToDD(value, ref) {
    return ref === "S" || ref === "W" ? -Math.abs(value) : Math.abs(value);
  }

  const handleMarkerClick = index => {
    const selectedImage = images[index];
    if (selectedImage) {
      setCurrentScene(selectedImage);
      setActiveMarkerIndex(index);
    }
  };
  const ref = useRef(null);
  return (
    <div>
      <Screenshot tagReference={ref} />
      <form>
        <div className="form-group row">
          <div
            className="vr-canvas-container"
            style={{ position: "relative", width: "88%", height: "88vh" }}
          >
            <a-scene
              embedded
              style={{ width: "100%", height: "100%" }}
              ref={ref}
            >
              {currentScene && (
                <>
                  <a-sky src={currentScene.src} rotation="0 -90 0"></a-sky>
                  <a-entity
                    camera
                    look-controls
                    wasd-controls="acceleration:1000000000"
                    position="0 1.6 0"
                  ></a-entity>
                  <a-light
                    type="ambient"
                    position="0 5 0"
                    intensity="0.5"
                  ></a-light>
                  <a-light
                    type="directional"
                    position="-3 3 1"
                    intensity="0.5"
                  ></a-light>
                </>
              )}
            </a-scene>
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                width: "250px",
                height: "250px",
                zIndex: 1000
              }}
            >
              <Minimap
                images={images}
                onMarkerClick={handleMarkerClick}
                activeMarker={activeMarkerIndex}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = state => ({
  email: state.auth.email
});

export default connect(mapStateToProps)(TourVirtual);
