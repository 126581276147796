import React from "react";
import { connect } from "react-redux";
import ClientSideTable from "components/reacttable/ClientSideTable";


const Downloads = props => {
  const activeColor = {
    color: "#3d85c6"
  };
  return (
    <div>
      <div className="plr-15">
        <ClientSideTable />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Downloads);
