const array = JSON.parse(localStorage.getItem("report"));
const initialStateReport = {
  report: array && array.length > 0 ? array : []
};
export default function report(state = initialStateReport, action) {
  switch (action.type) {
    case "ADD_TO_REPORT":
      const updatedReport = [...state.report, action.payload];
      localStorage.setItem("report", JSON.stringify(updatedReport));
      return {
        ...state,
        report: updatedReport
      };
    case "CLEAR_REPORT":
      localStorage.removeItem("report");
      return {
        ...state,
        report: []
      };
    case "REMOVE_REPORT":
      console.log(action.payload);
      const removeReports = state.report.filter(
        report => report.key !== action.payload
      );
      localStorage.setItem("report", JSON.stringify(removeReports));
      return {
        ...state,
        report: removeReports
      };

    default:
      return state;
  }
}
