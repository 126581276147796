import React, { useState, Fragment } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { folder } from 'helper/constant';
import { onlyDate } from "helper/methods";

const FileRow = ({ 
    media, 
    type, 
    openFileORFolder, 
    deleteFolder, 
    deleteFile, 
    isViewFolder, 
    moveToFolder, 
    moveFiletoRoot,
    openRenameFolderModal
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    return (
        <tr className="folder-row">
            <td className="folder-name fs-15 medium-text" onClick={() => openFileORFolder(media, type)}>
                {
                    type === "folder" ?
                    <Fragment>
                        <img src={folder} alt="file"/>
                        { media.name.length > 16 ? media.name.substring(0, 15)+'...' : media.name }
                    </Fragment> :
                    <Fragment>
                        <img className="border-radius-6" src={media.file} alt="file"/>
                        { media.name.length > 16 ? media.name.substring(0, 15)+'...' : media.name }
                    </Fragment>
                }
            </td>
            <td className="fs-15 medium-text">
                { onlyDate(media.created) }
            </td>
            <td>
            <div className="more-options">
                <i className="fas fa-ellipsis-h fs-16" id={`${type+media.id}`}></i>
            </div>
            <Popover 
                className="ds-menu"
                innerClassName="ds-inner-content"
                placement="bottom-end" 
                target={`${type+media.id}`} 
                trigger="legacy"
                isOpen={popoverOpen}
                toggle={() => setPopoverOpen(!popoverOpen)}
            >
                <PopoverBody onClick={() => setPopoverOpen(!popoverOpen)}>
                    {
                        type === 'folder' ?
                        <div>
                            <div
                                className="ds-menu-list"
                                onClick={() => openRenameFolderModal(media)}
                            >
                                Renomear
                            </div>  
                            <div
                                className="ds-menu-list"
                                onClick={() => deleteFolder(media)}
                            >
                                Deletar
                            </div>
                        </div> :
                        <div>
                            {
                                isViewFolder ? 
                                <div
                                    className="ds-menu-list"
                                    onClick={() => moveFiletoRoot(media)}
                                >
                                    Voltar Raiz
                                </div> :
                                <div
                                    className="ds-menu-list"
                                    onClick={() => moveToFolder(media)}
                                >
                                    Mover
                                </div>
                            }
                            <div
                                className="ds-menu-list"
                                onClick={() => deleteFile(media)}
                            >
                                Deletar Arquivo
                            </div>
                        </div>
                    }
                    
                </PopoverBody>
            </Popover>
            </td>
        </tr>
    );
};

export default FileRow;