import { 
    folder
} from 'helper/constant';
import React from 'react';
import Cartografia from 'views/projects/Cartografia';

export const foldersData = [
    {
        id: 1,
        name: 'Arquivos',
        created: 1558545033
    }
]

export const mediasData = [
    
    {
        id: 1,
        file: folder,
        name: 'Cartografia',
        component: <Cartografia/>,
        folder_id: 1,
        created: 1558545033
    }
]