import React from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { ProfileLockScreen } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
const { logout } = AuthActions;

const Header = props => {
  const { drawerMiniMethod, mini } = props;

  const userSignout = () => {
    props.logout();
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="container-fluid flex-x align-center justity-content-between">
          <div className="container-fluid">
            <div className="drawer-handle-arrow">
              {mini ? (
                <button
                  className="top-header-icon"
                  onClick={() => drawerMiniMethod()}
                >
                  <i className="fas fa-bars"></i>
                </button>
              ) : (
                <button
                  className="top-header-icon"
                  onClick={() => drawerMiniMethod()}
                >
                  <i className="fas fa-bars"></i>
                </button>
              )}
            </div>
            <div
              className="mini-drawer-menu-icon"
              onClick={() => drawerMiniMethod()}
            >
              <i className="fas fa-bars" />{" "}
              <span className="app-name fs-16 bold-text">{"DS Digital"}</span>
            </div>
          </div>
          <div className="pl-10">
            <div id="profile">
              <img
                className="top-header-profile-class"
                src={ProfileLockScreen}
                alt="notify"
              />
            </div>
            <UncontrolledPopover
              className="ds-menu"
              innerClassName="ds-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="ds-menu-list"
                  onClick={() => props.history.push("/perfil")}
                >
                  Perfil
                </div>
                <div className="ds-menu-list" onClick={userSignout}>
                  Sair
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default compose(withRouter, connect(null, { logout }))(Header);
