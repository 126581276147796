import React from "react";
import { connect } from "react-redux";
import { RowTableReport } from "./rowTableReports";
import { objetoMock } from "./objetoMockado";
function Reports({ email }) {
  return (
    <div className="container mt-3">
      <h4>Arquivos</h4>
      <table class="table table-striped table-bordered">
        <thead style={{ backgroundColor: "#035184" }} class="text-white">
          <tr>
            <th scope="col">Arquivos</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {objetoMock.map(file => (
            <RowTableReport key={file.id} filename={file.arquivo} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = state => ({
  email: state.auth.email
});

export default connect(mapStateToProps)(Reports);
