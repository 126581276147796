
import React, { useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { loginBack, ForgotIcon } from "helper/constant";
import Swal from "sweetalert2";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { token } = useParams();
    const history = useHistory();

    const loginContainer = {
        backgroundImage: `url(${loginBack})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "fixed",
        overflow: "auto",
        top: 0,
        bottom: 0
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'As senhas não coincidem.',
            });
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password/${token}`, { password });
            setMessage(response.data.message);
            setError('');
            Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: response.data.message,
            }).then(() => {
                history.push('/login');
            });
        } catch (error) {
            setError('Erro ao redefinir a senha.');
            setMessage('');
            Swal.fire({
                icon: 'error',
                title: 'Erro!',
                text: 'Erro ao redefinir a senha.',
            });
        }
    };

    return (
        <div className="container-fluid" style={loginContainer}>
            <div className="form-container">
                <div className="login-icon">
                    <img src={ForgotIcon} alt="icon" height="100px" />
                </div>
                <div className="login-title">Redefinir Senha</div>
                <form className="pa-24" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control react-form-input"
                            placeholder="Nova senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control react-form-input"
                            placeholder="Confirme a nova senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn form-button">
                        Redefinir Senha
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;